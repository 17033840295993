.splide-main {
	transform: translateY(-100%);
	z-index: 3;
}

.mbe-logo {
	background-image: url(../assets/svg/MBE.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.sbe-logo {
	background-image: url(../assets/svg/sbe.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.nycsbs-logo {
	background-image: url(../assets/svg/NYCSBS_logo.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.dbe-logo {
	background-image: url(../assets/svg/DBE.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.iw40-logo {
	background-image: url(../assets/svg/iw40.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.iw361-logo {
	background-image: url(../assets/svg/iw361.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
