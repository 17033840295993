.services-main {
	transform: translateY(-112px);
}

.border-class {
	border: 1px solid red;
}

@media screen and (max-width: 626px) {
	.read-more {
		display: none;
	}
}
