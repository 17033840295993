.typewriter-cont > span {
	font-size: 16px;
}

@media screen and (max-width: 400px) {
	.message-container {
		width: 23rem;
	}

	.typewriter-cont > span,
	.message-author {
		font-size: 16px;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.monadnockLogo {
	background-image: url("../assets/misc-files/monadnock.png");
}

.minelliLogo {
	background-image: url("../assets/misc-files/minelliLogo.png");
}

.forteLogo {
	background-color: #494f55;
	background-image: url("../assets/misc-files/forteLogo.webp");
}

.scaLogo {
	background-image: url("../assets/misc-files/scaLogo.jpg");
}

.top8Logo {
	background-image: url("../assets/misc-files/top8Logo.png");
}

.wdfLogo {
	background-image: url("../assets/misc-files/wdfLogo.gif");
}

.companyLogos {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 6rem;
	width: 8rem;
}

.arnellLogo {
	background-image: url("../assets/misc-files/arnell.svg");
}

@media screen and (min-width: 601px) and (max-width: 767px) {
	.message-container {
		min-height: 31rem;
	}
}

@media screen and (min-width: 472px) and (max-width: 598px) {
	.message-container {
		min-height: 37rem;
	}
}

@media screen and (max-width: 473px) {
	.message-container {
		min-height: 39rem;
	}
}
