@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	margin: 0;
}

body {
	margin: 0;
	font-family: "Inter", sans-serif;
	font-feature-settings: "liga" 1, "calt" 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.paragraph-text {
	max-width: 75ch;
}
