.brand-link > span {
	color: #494f55;
}

@media screen and (max-width: 640px) {
	.socials {
		justify-content: space-evenly;
	}

	.brand-link {
		width: fit-content;
	}
}
