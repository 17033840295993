#email {
	border-bottom: 1px solid #fca311;
}
#subject {
	border-bottom: 1px solid #fca311;
}
#message {
	border-bottom: 1px solid #fca311;
}
#phone {
	border-bottom: 1px solid #fca311;
}
#name {
	border-bottom: 1px solid #fca311;
}
#companyName {
	border-bottom: 1px solid #fca311;
}

textarea {
	height: 100%;
	width: 100%;
}

input:focus,
textarea:focus {
	outline: none;
}
