.splide__pagination {
	background-color: #14213d;
	width: fit-content;
	margin: auto;
	display: none;
	align-items: center;
	border-radius: 10px;
	padding-bottom: 5px;
}

.splide__track {
	justify-content: center;
}

.gallery-splide-track {
	padding-left: 15rem !important;
	padding-right: 15rem !important;
}

.splide__slide {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.splide__arrow {
	background-color: transparent;
	opacity: 1;
	height: 5rem;
	width: 5rem;
}

.splide__arrow svg {
	fill: #fca311;
	height: 5rem;
	width: 5rem;
}

.splideArrow {
	color: #fca311;
}

.splideArrowLeft {
	transform: rotate(360deg) !important;
	margin-right: 1rem;
}

.splideArrowRight {
	margin-left: 1rem;
}

@media screen and (max-width: 1534px) {
	.gallery-splide-track {
		padding-left: 11rem !important;
		padding-right: 11rem !important;
	}
}

@media screen and (max-width: 1279px) {
	.gallery-splide-track {
		padding-left: 8rem !important;
		padding-right: 8rem !important;
	}
}

@media screen and (max-width: 1040px) {
	.gallery-splide-track {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}

@media screen and (max-width: 640px) {
	.splide__arrows {
		display: none;
	}
}
