@media screen and (min-width: 1280px) {
	.locations-cont {
		background: linear-gradient(
			to right,
			#fca311 0%,
			#fca311 50%,
			#40444a 50%,
			#40444a 100%
		);
	}
}

.tt_sm {
	border-radius: 6px;
	box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
	z-index: 1000000;
	background-color: white;
	padding: 7px;
	opacity: 1;
	font: 12px/1.5 Inter, sans-serif;
	color: #1e2023;
}
.tt_name_sm {
	float: left;
	font-weight: bold;
}
.sm_label {
	font-weight: normal !important;
}
.xmark_sm {
	float: right;
	margin-left: 5px;
	cursor: pointer;
	line-height: 0px;
}
.tt_custom_sm {
	color: #1e2023;
	font-weight: bolder;
}
.tt_mobile_sm {
	margin-top: 5px;
}
.btn_simplemaps {
	color: black;
	text-decoration: none;
	background: #ffffff;
	display: inline-block;
	padding: 5px 5px;
	margin: 0;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	line-height: 1.43;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid;
	border-radius: 4px;
}
.btn_simplemaps:hover {
	text-decoration: underline;
}
